export function fileToBase64 (file: File | Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const { result } = reader
      if (typeof result === "string") resolve(result)
      else reject(Error("Can't convert to base64"))
    }
    reader.onerror = error => reject(error)
  })
}

function urlDownload (url: string, fileName: string) {
  const a = document.createElement("a")
  document.body.appendChild(a)
  a.href = url
  a.download = fileName
  a.click()
  a.remove()
}

export async function downloadFile (file: File | Blob, fileName: string) {
  try {
    const url = URL.createObjectURL(file)
    urlDownload(url, fileName)
    URL.revokeObjectURL(url)
  } catch (e) {
    console.error(e)

    // Фоллбэк
    try {
      const b64 = await fileToBase64(file)
      urlDownload(b64 as string, fileName)
    } catch (e) { console.error(e) }
  }
}

// export async function printPdf (pdf: Blob) {
//   try {
//     const url = URL.createObjectURL(pdf)
//     const { default: UAParser } = await import("ua-parser-js")
//     const uaParser = new UAParser()
//     const browser = uaParser.getBrowser().name
//     const deviceType = uaParser.getDevice().type
//     if (deviceType === "mobile" && browser === "Chrome")
//       window.location.href = url
//     else {
//       const { default: printjs } = await import("print-js")
//       printjs(url)
//     }
//   } catch (e) { console.error(e) }
// }
