import { nanoid } from "nanoid"

export default {
  inheritAttrs: false,

  props: {
    /* eslint-disable vue/require-prop-types */
    value: { required: true },
    rules: { default: null },
    /* eslint-enable vue/require-prop-types */
    vid: { type: String, default: undefined },
    label: { type: String, default: "" },
    error: { type: String, default: "" },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data: () => ({
    id: "",
  }),

  mounted () {
    this.id = nanoid()
  },
}
