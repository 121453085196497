
import buttonMixin from "@/mixins/button"

export default {
  mixins: [buttonMixin],

  props: {
    isPale: { type: Boolean, default: false },
    isOutline: { type: Boolean, default: false },
    isSm: { type: Boolean, default: false },
    variant: { type: String, default: "primary" },
  },
}
