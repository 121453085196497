
import fieldMixin from "@/mixins/field"
import { fileToBase64 } from "@/utils/file"

export default {
  mixins: [fieldMixin],

  props: {
    // общие
    as: { type: String, default: "input" },
    help: { type: String, default: "" },
    autoWidth: { type: Boolean, default: false },

    // input, textarea
    // eslint-disable-next-line vue/require-prop-types
    mask: { default: null },
    noMask: { type: Boolean, default: false },
    formatter: { type: Function, default: null },

    // select
    options: { type: [Object, Array], default: () => ({}) },
    isEmptyAllowed: { type: Boolean, default: false },
    preferred: { type: Array, default: () => [] },
    noPlaceholder: { type: Boolean, default: false },

    // file
    accept: { type: String, default: undefined },
    // KB
    maxSize: { type: Number, default: Infinity },
    withPreview: { type: Boolean, default: false },
    doUpload: { type: Boolean, default: false },
  },

  computed: {
    model: {
      get () { return this.value ?? "" },
      set (model) {
        this.$emit("input", model)
        this.error_ = ""
      },
    },

    error_: {
      get () { return this.error },
      set (error) { this.$emit("update:error", error) },
    },

    attrs () {
      return {
        ...this.$attrs,
        required: this.required,
        disabled: this.disabled,
        id: this.id,
        autocomplete: "new-password",
      }
    },

    label_ () {
      if (this.label) return this.label
      if (!["input"].includes(this.as)) return ""
      const { type } = this.$attrs
      if (type === "email") return this.$t("input.label.email")
      if (type === "password") return this.$t("input.label.password")
      if (type === "tel") return this.$t("input.label.phone")
      return ""
    },

    placeholder_ () {
      if (!["input", "textarea", "select"].includes(this.as)) return ""
      if (this.$attrs.placeholder) return this.$attrs.placeholder
      const { type } = this.$attrs
      if (type === "email") return "user@example.com"
      if (type === "password") return this.$t("input.placeholder.password")
      if (type === "tel") return this.$t("input.placeholder.phone")
      if (type === "url") return "https://example.com"
      return ""
    },

    mask_ () {
      if (this.noMask) return null
      if (!["input", "textarea"].includes(this.as)) return null
      if (this.mask) return this.mask
      const { type } = this.$attrs
      if (type === "tel") return "+# (###) ### ##-##"
      return null
    },

    rules_ () {
      if (this.rules) return this.rules
      if (this.as === "file")
        return { size: this.maxSize, mimes: this.accept.split(",") }
      if (this.$attrs.type === "url") return "url"
      return null
    },

    remainingChars () {
      const { maxlength } = this.$attrs
      if (!maxlength) return Infinity
      return maxlength - (this.model?.length ?? 0)
    },

    options_ () {
      const { options } = this
      if (Array.isArray(options))
        return Object.fromEntries(options.map(o => [o, o]))
      return options
    },
    preferredOptions () {
      const { preferred } = this
      return Object.fromEntries(preferred.map(k => [k, this.options_[k]]))
    },
  },

  created () { this.onInputChange() },

  methods: {
    async onFileChange (e) {
      const file = e?.target?.files?.[0]
      if (!file) return

      const { valid } = await this.$refs.provider?.validate(e)
      if (!valid) return

      if (this.doUpload) {
        // const [res, err] = await this.$api.upload.uploadTmpFile(file)
        // if (err) {
        //   this.error_ = err
        //   return
        // }
        // console.log(res)
      } else {
        try {
          const dataUrl = await fileToBase64(file)
          this.model = { file: dataUrl, name: file.name }
        } catch (e) {
          console.error(e)
          this.error_ = this.$t("errors.error")
        }
      }
    },

    onInputChange () {
      const { model } = this
      const { type } = this.$attrs
      if (type === "url") {
        if (/^https?:?\/?\/?$/.test(model)) this.model = ""
        else if (model && !model.startsWith("http"))
          this.model = `http://${model}`
      }
      const { formatter } = this
      if (formatter) this.model = formatter(model)
    },
  },
}
